<template>
  <el-container class="schedule_detail m-t-60">
    <el-header class="my-header" style="font-size: 12px">
      <el-row type="flex" class="row-bg" justify="space-between">
        <el-col :span="8" :xs="6">
          <el-button type="text" class="header_back_button" icon="el-icon-arrow-left" @click="backPage()">Буцах
          </el-button>
        </el-col>
        <el-col :span="8" :xs="12" style="text-align: center;">
          <span class="header_center_title">Хуваарь шилжүүлэх</span>
        </el-col>
        <el-col :span="8" :xs="6">
          <div class="flr">
            <span class="m-r-10" prob="first_name">{{ user.name }}</span>
          </div>
        </el-col>
      </el-row>
    </el-header>
    <el-row type="flex" class="row-bg" justify="center" v-if="transferDetail?.student_id?.length === 0">
      <el-col :span="12" class="p-30" style="text-align: center;">
        <el-image
        class="m-l-30"
      style="width: 70px; height: 70px"
      :src="emptyImg"
      fit="fill"></el-image>
      <span class="disabled_text">Уучлаарай хүүхэд шилжүүлэх жагсаалт хоосон байна.</span>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg m-b-100" justify="center" v-else>
      <el-col :span="23" class="home_body">
        <div class="schedule_body_info_students m-t-20 m-b-20">
          <span><span class="m-l-25">Сурагчийн нэр</span></span>
          <div v-if="transferStudent?.length > 0">
            <el-card v-for="student, index in transferStudent" :key="index" :class="isSelected(student.id) ? 'm-t-10 cursor transfer_student_card_active' : 'm-t-10 cursor transfer_student_card'"
              @click.native="selectStudent(student.id)">
              <div class="student-card-list-body">
                <div>
                  {{ index + 1 }}.
                  {{ student.name.slice(0, 2) + '.' + student.last_name }}
                </div>
                <div v-if="isSelected(student.id)"><i class="el-icon-circle-check checked-icon"></i></div>
              </div>
            </el-card>
          </div>
          <br>
           <el-button size="mini" class="w-100 p-10" style="border-radius:10px" type="success"
            @click="next()">
            Үргэжллүүлэх
          </el-button><br/>
          <el-button size="mini" class="w-100 p-10 m-t-10" style="border-radius:10px" type="info" @click="cancelRequest()">
            Цуцлах
          </el-button>
        </div>
      </el-col>
    </el-row>
    <el-dialog title="Хуваарь сонгох" :visible.sync="dialogVisible" style="top:25vh" width="80%">
      <!-- {{ schedules }} -->
      <h4 style="color: red;">Шилжүүлсэн ээлж : <b>{{ transferDetail.eelj }} -р ээлж</b></h4>
      <el-select class="w-100 m-t-20" v-model="selectedSchedule" placeholder="- Сонгох -">
        <template v-for="schedule in schedules">
          <el-option
          :key="schedule.id"
          :label="schedule.eelj + '-р ээлж'"
          :value="schedule.id"></el-option>
        </template>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Болих</el-button>
        <el-button type="primary" @click="confirmScheduleTransfer()">Хадгалах</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import services from '@/helpers/services'
import { Notification } from 'element-ui'
import SrcPathImage from '@/assets/empty.png'

export default {
  data: () => {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      transferDetail: {
        student_id: []
      },
      transferStudent: [],
      checkedStudents: [],
      dialogVisible: false,
      schedules: [],
      selectedSchedule: null,
      emptyImg: SrcPathImage
    }
  },
  mounted () {
    this.getTransferInfo()
    console.log(this.transferDetail, 'ss')
  },
  methods: {
    cancelRequest () {
      if (this.checkedStudents.length > 0) {
        this.$confirm('Хүсэлтийг цуцлахдаа итгэлтэй байна уу?', 'Анхаар', {
          confirmButtonText: 'Тийм',
          cancelButtonText: 'Буцах',
          type: 'warning'
        }).then(() => {
          this.$root.fullscreenLoading = true
          const params = {
            id: this.$route.params.id,
            students: this.checkedStudents,
            schedule: this.selectedSchedule
          }

          services.removeTransferStudentsOnSchedule(params).then((response) => {
            if (response.status === 'success' && response.result === 'done') {
              window.history.back()
            } else {
              Notification({
                message: 'Алдаа гарлаа таны сурагч өмнө нь шилжүүлэх үйлдэлд гацсан байна!',
                duration: 2500,
                type: 'error',
                showClose: 1,
                position: 'top-right'
              })
              this.$root.fullscreenLoading = false
            }
          })
        }).catch(() => {

        })
      } else {
        Notification({
          message: 'Сурагчаа сонгоно уу!',
          duration: 2500,
          type: 'error',
          showClose: 1,
          position: 'top-right'
        })
      }
    },
    backPage () {
      this.$router.back()
    },
    selectStudent (id) {
      const index = this.checkedStudents.indexOf(id)
      if (index > -1) {
        this.checkedStudents.splice(index, 1)
      } else {
        this.checkedStudents.push(id)
      }
    },
    isSelected (id) {
      return this.checkedStudents.includes(id)
    },
    getTransferInfo () {
      const param = {
        id: this.$route.params.id
      }

      services.getTransferDetail(param).then((response) => {
        this.transferDetail = response.result.transferList
        this.schedules = response.result.posibleSchedule
        this.transferStudent = response.result.students
        this.schedules.forEach(element => {
          if (element.eelj) {
            if (element.eelj.toString() === this.transferDetail.eelj) {
              this.selectedSchedule = element.id
            }
          }
        })
      })
    },
    next () {
      if (this.checkedStudents.length > 0) {
        this.dialogVisible = true
      } else {
        Notification({
          message: 'Сурагчаа сонгоно уу!',
          duration: 2500,
          type: 'error',
          showClose: 1,
          position: 'top-right'
        })
      }
    },
    confirmScheduleTransfer () {
      this.$root.fullscreenLoading = true
      const params = {
        id: this.$route.params.id,
        students: this.checkedStudents,
        schedule: this.selectedSchedule
      }

      services.confirmTransfer(params).then((response) => {
        if (response.status === 'success' && response.result === 'done') {
          window.history.back()
        } else {
          window.location.reload()
        }
      })
    }
  }
}
</script>
