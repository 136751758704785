import Vue from 'vue'
import Vuex from 'vuex'
import services from '@/helpers/services.js'
// import { notification } from '@/helpers/custom.js'

Vue.use(Vuex)
const idToken = window.localStorage.getItem('idToken')
const userId = window.localStorage.getItem('userId')
const role = window.localStorage.getItem('role')
const user = window.localStorage.getItem('user')
const localScheduleList = window.localStorage.getItem('localScheduleList')
const localSelectedDetail = window.localStorage.getItem('localSelectedDetail')

export default new Vuex.Store({
  state: {
    idToken: idToken ? JSON.parse(idToken) : null,
    userId: userId ? JSON.parse(userId) : null,
    role: role ? JSON.parse(role) : [],
    userInfo: user ? JSON.parse(user) : null,
    scheduleList: localScheduleList ? JSON.parse(localScheduleList) : [],
    selectedDetail: localSelectedDetail ? JSON.parse(localSelectedDetail) : null
  },
  getters: {
    userGetter (state) {
      return state.userInfo
    },
    idToken (state) {
      return state.idToken
    },
    getterScheduleList (state) {
      return state.scheduleList
    },
    getterScheduleDetail (state) {
      return state.selectedDetail
    }
  },
  mutations: {
    authUser (state, userData) {
      state.idToken = userData.token
      state.user = userData.user
      window.localStorage.setItem('idToken', JSON.stringify(state.idToken))
      window.localStorage.setItem('user', JSON.stringify(state.user))
    },
    addRole (state, roleData) {
      state.role = roleData
      window.localStorage.setItem('role', JSON.stringify(state.role))
    },
    addUserInfo (state, userData) {
      state.userInfo = userData
      window.localStorage.setItem('user', JSON.stringify(state.userInfo))
    },
    addScheduleListAdd (state, scheduleDetail) {
      state.scheduleList = scheduleDetail
      window.localStorage.setItem('localScheduleList', JSON.stringify(state.scheduleList))
    },
    addDetailSchedule (state, data) {
      state.selectedDetail = data
      window.localStorage.setItem('localSelectedDetail', JSON.stringify(state.selectedDetail))
    }
  },
  actions: {
    addScheduleSelectedDetail ({ commit }, response) {
      commit('addDetailSchedule', response)
    },
    addScheduleDetailCommit ({ commit }, response) {
      commit('addScheduleListAdd', response)
    },
    authUserLoginPage ({ commit }, response) {
      commit('authUser', response)
    },
    addRoleLoginPage ({ commit }, response) {
      commit('addRole', response)
    },
    userAdd ({ commit }, response) {
      commit('addUserInfo', response)
    },
    signin ({ commit }, authData) {
      return new Promise((resolve, reject) => {
        services.login(authData)
          .then((response) => {
            resolve(response)
          },
          error => {
            reject(error)
          })
      })
    }
  },
  modules: {
  }
})
