import { render, staticRenderFns } from "./mainLayout.vue?vue&type=template&id=4e51b865&scoped=true&"
import script from "./mainLayout.vue?vue&type=script&lang=js&"
export * from "./mainLayout.vue?vue&type=script&lang=js&"
import style0 from "./mainLayout.vue?vue&type=style&index=0&id=4e51b865&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e51b865",
  null
  
)

export default component.exports