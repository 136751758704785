<template>
  <el-container class="home">
          <el-header class="my-header" style="font-size: 12px">
            <span class="m-l-10">Хуваарь</span>
            <div class="flr">
        <span class="m-r-10" prob="first_name">{{user.name}}</span>
        <el-dropdown>
          <i class="el-icon-caret-bottom"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <el-button @click="logout()">Гарах</el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        </div>
      </el-header>
      <el-row>
      </el-row>
      <el-row class="m-t-60">
        <el-col :span="22" :offset="1"><el-row :gutter="20" type="flex" class="row-bg m-t-10" justify="space-around">
                <el-col :span="12">
                    <div class="schedule_detail_count p-10">
                    7 хоногт хичээл орох тоо <br/>
                    <span style="color:#281C9D">6</span>
                    </div>
                </el-col>
                   <el-col :span="12">
                    <div class="schedule_detail_count">
                    Суралцаж байгаа сурагчийн тоо <br/>
                    <span style="color:green">250</span>
                    </div>
                </el-col>
            </el-row>
            </el-col>

      </el-row>
    <el-row type="flex" class="row-bg m-b-100" justify="center">
      <el-col :span="22" class="home_body m-t-20"  v-loading="loading">
          <!-- <el-select v-model="day_value" placeholder="Гараг сонгох" class="w-100" @change="getTeacherLessonSchedule">
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value">
    </el-option>
  </el-select> -->
  <div>
        <el-card shadow="always" class="m-t-10" style="border-radius:20px; line-height:2" v-for="scheduleItem, index in this.scheduleList" :key="index" >
          <span class="eelj_text"> {{scheduleItem.eelj}} ээлж</span>
          <span class="flr">{{scheduleItem.start_time}} - {{scheduleItem.end_time}}</span>
          <br/>
          <span >{{scheduleItem.students.length}} сурагч</span>
          <el-button v-if="scheduleItem.students.length > 0" class="flr transfer_btn" size="mini" @click="goToScheduleTransportPage(scheduleItem)">Хуваарь шилжүүлэх</el-button>
          <br/><span v-if="scheduleItem.room">{{scheduleItem.room.room_name}} тоот</span>
          <el-button class="flr m-b-20 m-t-10 detail_text" size="mini"   @click="goToScheduleDetailPage(scheduleItem)" type="info">Дэлгэрэнгүй</el-button>
        </el-card>
        </div>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
import services from '@/helpers/services.js'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      scheduleList: 'getterScheduleList'
    })
  },
  data () {
    return {
      loading: true,
      token: JSON.parse(localStorage.getItem('idToken')),
      user: JSON.parse(localStorage.getItem('user'))
    }
  },
  created () {
    console.log(window.CSRF_TOKEN = '{{ csrf_token() }}', this.scheduleList)
    this.getTeacherLessonSchedule()
  },
  methods: {
    logout () {
      localStorage.clear()
      this.$router.push({
        path: '/login'
      })
    },
    getTeacherLessonSchedule () {
      this.loading = false
      const payload = {
        eelj: 1
      }
      services.getTeacherSchedule(payload)
        .then(response => {
          this.$store.dispatch('addScheduleDetailCommit', response.data)
          this.loading = false
        }).catch((err) => {
          console.log(err)
        })
    },
    goToScheduleDetailPage (data) {
      this.$store.dispatch('addScheduleSelectedDetail', data)
      this.$router.push({ name: 'ScheduleDetail', params: { passData: data } })
    },
    goToScheduleTransportPage (schedule) {
      this.$router.push({ name: 'ScheduleTransform', params: { selectedSchedule: schedule } })
    }
  }
}

</script>

<style>
 .el-header {
    background-color: #281C9D;
    color: rgb(255, 255, 255);
    line-height: 60px;
    padding: 0px;
    padding-right: 40px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    position: relative;
    width: 100%;
    left: 0;
    top: 0;
  }

</style>
