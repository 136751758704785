<template>
  <el-container class="schedule_detail m-t-60">
    <el-header class="my-header" style="font-size: 12px">
      <el-row type="flex" class="row-bg" justify="space-between">
        <el-col :span="8" :xs="6">
          <el-button type="text" class="header_back_button" icon="el-icon-arrow-left" @click="backPage()">Буцах
          </el-button>
        </el-col>
        <el-col :span="8" :xs="12" style="text-align: center;">
          <span class="header_center_title">Хуваарь шилжүүлэх</span>
        </el-col>
        <el-col :span="8" :xs="6">
          <div class="flr">
            <i class="el-icon-message-solid m-r-4" @click="goNotificationsPage()"></i>
            <span class="m-r-10" prob="first_name">{{ user.name }}</span>
          </div>
        </el-col>
      </el-row>
    </el-header>
    <el-row type="flex" class="row-bg m-b-100" justify="center">
      <el-col :span="23" class="home_body">
        <div class="schedule_body_info_students m-t-20 m-b-20">
          <span><span class="m-l-25 notification_title">Сурагчийн нэр</span></span>
          <div v-if="scheduleLogDetail.length > 0">
            <el-card v-for="attendance, index in scheduleLogDetail" :key="index" :class="isSelected(attendance?.id) ? 'm-t-10 cursor transfer_student_card_active' : 'm-t-10 cursor transfer_student_card'"
              @click.native="selectStudent(attendance?.id)">
              <div class="student-card-list-body">
                <div>
                  {{ index + 1 }}.
                  {{ attendance?.name.slice(0, 2) + '.' + attendance?.last_name }}
                </div>
                <div v-if="isSelected(attendance?.id)"><i class="el-icon-circle-check checked-icon"></i></div>
              </div>
            </el-card>
          </div>
          <br>
          <el-button size="mini" class="m-t-10 m-b-10 w-100 p-10" style="border-radius:10px" type="success"
            @click="dialogVisible = true">
            Шилжүүлэх
          </el-button>
        </div>
      </el-col>
    </el-row>
    <el-dialog title="Багш сонгох" :visible.sync="dialogVisible" style="top:25vh" width="80%">
      <el-select class="w-100" v-model="sendTeacher" placeholder="- Сонгох -">
        <el-option v-for="teacher in teacherList" :key="teacher.id"
          :label="teacher.last_name + '.' + teacher.name" :value="teacher.id"></el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Болих</el-button>
        <el-button type="primary" @click="saveScheduleTransform()">Хадгалах</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
import services from '@/helpers/services'
import { Notification } from 'element-ui'

export default {
  data: () => {
    return {
      dialogVisible: false,
      user: JSON.parse(localStorage.getItem('user')),
      teacherList: [],
      sendTeacher: null,
      scheduleLogDetail: [],
      checkedStudents: [],
      position: null
    }
  },
  beforeMount () {
    this.getScheduleStundents()
    const totalTeacher = []
    services.getTeachersByLessonType().then((response) => {
      response.result.forEach(element => {
        element.forEach(e => {
          totalTeacher.push(e)
        })
      })
      this.teacherList = totalTeacher
    })
  },
  methods: {
    getScheduleStundents () {
      const param = {
        schedule_id: this.$route.params.selectedSchedule.id
      }

      services.getScheduleLogByIdTuguu(param).then((response) => {
        this.scheduleLogDetail = response.data
        this.position = response.data.position
      })
    },
    saveScheduleTransform () {
      const params = {
        transfer_teacher_id: this.user.id,
        approve_teacher_id: this.sendTeacher,
        student_id: this.checkedStudents,
        schedule_id: parseInt(this.$route.params.selectedSchedule.id),
        eelj: this.$route.params.selectedSchedule.eelj
        // position: this.position
      }
      services.saveScheduleTransform(params).then((response) => {
        if (response.status === 'success') {
          this.dialogVisible = false
          Notification({
            message: 'Амжилттай хадгалагдлаа',
            duration: 2500,
            type: 'success',
            showClose: 1,
            position: 'top-right'
          })
          window.location.reload()
        }
      })
    },
    selectStudent (id) {
      const index = this.checkedStudents.indexOf(id)
      if (index > -1) {
        this.checkedStudents.splice(index, 1)
      } else {
        this.checkedStudents.push(id)
      }
    },
    isSelected (id) {
      return this.checkedStudents.includes(id)
    },
    backPage () {
      this.$router.back()
    },
    goNotificationsPage () {
      this.$router.push('/notifications')
    }
  }
}
</script>

<style>
.el-card__body {
  padding: 14px 20px !important;
}
</style>
