import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from '../views/login/LoginPage.vue'
import MainLayout from '../views/layout/mainLayout.vue'
import Home from '../views/home/HomePage.vue'
import ScheduleDetail from '@/views/scheduleDetail/scheduleDetail.vue'
import ScheduleTransform from '@/views/scheduleTransform/scheduleTransform.vue'
import ScheduleTransferRequest from '@/views/scheduleTransferRequest/scheduleTransferRequest.vue'
import Notifications from '@/views/notifications/notificationView.vue'
import ProfilePage from '@/views/profile/profilePage.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: MainLayout,
    redirect: '/home',
    meta: {
      requiresAuth: true
    },
    children: [{
      path: '/home',
      name: 'Home',
      component: Home,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/profile',
      name: 'Profile',
      component: ProfilePage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/scheduleDetail',
      name: 'ScheduleDetail',
      component: ScheduleDetail,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/scheduleTransform/:id',
      name: 'ScheduleTransform',
      component: ScheduleTransform,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/transferScheduleRequest/:id',
      name: 'transferScheduleRequest',
      component: ScheduleTransferRequest,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/notifications',
      name: 'Notifications',
      component: Notifications,
      meta: {
        requiresAuth: true
      }
    }
    ]
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    meta: {
      requiresAuth: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // const result = getRole()
  // console.log(to.meta.requiresAuth, 'too')

  if (to.meta.requiresAuth && !store.state.idToken) {
    next({
      name: 'LoginPage'
    })
  } else if (!to.meta.requiresAuth) {
    next()
  } else {
    // console.log(asyncCall(), 'sda')
    if (store.state.user) {
      if (store.state.user.name != null) {
        next()
      } else {
        next({
          name: 'AuthError'
        })
      }
    } else {
      next()
    }
  }
})

export default router
