import {
  mainRequests
} from './custom'
export default {
  login (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .post('/login', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getRole () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getRole')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getUserById (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getUserById', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getTeacherSchedule (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getLessonSchedule', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createScheduleLog (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createScheduleLog', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createAttendance (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createAttendance', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getScheduleLogByIdTuguu (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getStudentScheduleById', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getScheduleLogById (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getScheduleLogById', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getScheduleById (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getScheduleById', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getTeachersByLessonType (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/getSameTeacher', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  saveScheduleTransform (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createTransferStudents', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getNotificationList () {
    return new Promise(function (resolve, reject) {
      mainRequests
        .getAuth('/notifications')
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getTransferDetail (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/getTransferDetails', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  confirmTransfer (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/confirmTransferStudents', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  removeTransferStudentsOnSchedule (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/removeTransferStudentsOnSchedule', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  createStudentNote (payload) {
    return new Promise(function (resolve, reject) {
      mainRequests
        .postAuth('/createStudentNote', payload)
        .then(res => {
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}
