<template>
  <el-container class="schedule_detail m-t-60">
    <el-header class="my-header" style="font-size: 12px">
      <el-row type="flex" class="row-bg" justify="space-between">
        <el-col :span="8" :xs="6">
          <el-button type="text" class="header_back_button" icon="el-icon-arrow-left" @click="backPage()">Буцах
          </el-button>
        </el-col>
        <el-col :span="8" :xs="12" style="text-align: center;">
          <span class="header_center_title">Мэдэгдэл</span>
        </el-col>
        <el-col :span="8" :xs="6">
          <div class="flr">
            <span class="m-r-10" prob="first_name">{{ user.name }}</span>
          </div>
        </el-col>
      </el-row>
    </el-header>
    <el-row type="flex" class="row-bg" justify="center" v-if="notificationList.length == 0">
      <el-col :span="12" class="p-30" style="text-align: center;">
        <el-image
        class="m-l-30"
      style="width: 70px; height: 70px"
      :src="emptyImg"
      fit="fill"></el-image>
      <span class="disabled_text">Уучлаарай хүүхэд шилжүүлэх хүсэлт ирээгүй байна.</span>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg m-b-100" justify="center" v-else>
      <el-col :span="23" class="home_body">
        <div class="schedule_body_info_students m-t-20 m-b-20">
          <span><span class="color_gray">Мэдэгдэлийн жагсаалт</span></span>
          <div v-if="notificationList.length > 0">
            <el-card v-for="notification, index in notificationList"
              :key="index"
              class="m-t-10 notification_card cursor-pointer"
              shadow="hover"
              @click.native="notificationDetail(notification.redirect_link)">
              <el-row>
                <el-col :span="18" :xs="14">
                  <span class="notification_title">{{ notification.title }}</span>
                  <p class="notification-detail">{{ notification.description }}</p>
                </el-col>
                <el-col :span="6" :xs="10" style="text-align: right;">
                  <div><i v-if="notification.read === 0" class="unread-dot"></i> <p v-else style="height:18px"></p></div>
                  <p class="notification_created_at">{{ notification.created_at }}</p>
                </el-col>
              </el-row>
            </el-card>
          </div>
        </div>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
import services from '@/helpers/services'
import SrcPathImage from '@/assets/empty.png'

export default {
  data: () => {
    return {
      user: JSON.parse(localStorage.getItem('user')),
      notificationList: [],
      emptyImg: SrcPathImage
    }
  },
  beforeCreate () {
    this.$root.fullscreenLoading = true
    services.getNotificationList().then((response) => {
      this.$root.rootNotificationCount = response.data.length
      this.notificationList = response.data
      this.$root.fullscreenLoading = false
    })
  },
  methods: {
    backPage () {
      this.$router.back()
    },
    notificationDetail (link) {
      this.$router.push(link)
    }
  }
}
</script>

<style>
.unread-dot{
  border: 1px solid #4CA5A2;
  background-color: #4CA5A2;
  border-radius: 50%;
  display: inline-block;
  height: 10px;
  width: 10px;
}
.cursor-pointer{
  cursor: pointer;
}
.notification-detail{
  color:#b3b3b3;
  font-weight: 200;
  line-height: 2rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
