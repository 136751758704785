<template>
    <el-container class="profile_detail">
            <el-header class="profile-header textcenter">
              <span>Тохиргоо</span>
              <div class="flr">
          </div>
        </el-header>
      <el-row type="flex" class="row-bg m-b-100 m-t-20" justify="center">
        <el-col :span="24" class="home_body">
          <el-card :body-style="{ padding: '0px' }" shadow="always" class="settings_card p-t-30">
            <div class="img_profile_top textcenter">
                <img :src="'https://image.dma.mn/' + userLocalStorage.img_url" width="100px" class="teacher_image_profile"><br/>
                <h3>{{userLocalStorage.last_name+' ' +userLocalStorage.name }}</h3>
            </div>
            <div>
                <el-row class="profile_row">
                    <el-col :span="12">
                        Холбоо барих
                    </el-col>
                    <el-col :span="12">
                        {{ userLocalStorage.phone }}
                    </el-col>
                </el-row>
                <el-row class="profile_row">
                    <el-col :span="12">
                        РД :
                    </el-col>
                    <el-col :span="12">
                        {{ userLocalStorage.regno }}
                    </el-col>
                </el-row>
                <el-row class="profile_row"  type="flex" justify="center">
                    <el-col :span="3">
                        <el-button @click="logout()" class="setting_logout_button" type="text">ГАРАХ</el-button>
                    </el-col>
                </el-row>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </el-container>
  </template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      userLocalStorage: 'userGetter'
    })
  },
  data () {
    return {
      loading: true
    }
  },
  created () {
  },
  methods: {
    logout () {
      localStorage.clear()
      this.$router.push({
        path: '/login'
      })
    }
  }
}
</script>

  <style>

  </style>
